import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ButacasSeleccion from "../Components/Entradas/Butacas/ButacasSeleccion";
import PeliculaSimple from "../Components/Entradas/Pelicula/PeliculaSimple";
import Headline from "../Components/HeadlineTimer/Headline";
import Carrito from "../Components/Carrito/Carrito";
import { useDispatch, useSelector } from "react-redux";
import { cargarButacas, eliminarButaca } from "../actions/butacasActions";
import { Box, Grid, Stack, CircularProgress } from "@mui/material";
import useCurrentWindow from "../hooks/useCurrentWindow";
import { resetButacas, setNextPage } from "../actions/carritoActions";
import { Link } from "react-router-dom";
import BackBTN from "../Components/BackBtn";
import apihost from "../config/apiHost.json";

function Butacas() {
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const windowSize = useCurrentWindow();
  const titleHeading = "Selección de butacas";
  const datosButaca = useSelector((state) => state.DatosButaca);
  const datosFuncion = useSelector((state) => state.DatosFuncion);
  const datosPelicula = useSelector((state) => state.DatosPelicula);
  const datosCarrito = useSelector((state) => state.DatosCarrito.tarifas);
  const nextPage = useSelector((state) => state.DatosCarrito.nextPage);
  const errorWs = useSelector((state) => state.DatosCarrito.errorWs);
  const [isLoading, setIsLoading] = useState(true);
  const [isVertical, setIsVertical] = useState(false);
  const [disabledContinue, setDisabledContinue] = useState(true);

  const cine = urlParams.get("cid");

  useEffect(() => {
    if (datosCarrito && datosCarrito.tarifas) {
      for (let i = 0; i < datosCarrito.tarifas; i++) {}
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      dispatch(
        cargarButacas(
          apihost.REACT_APP_URL_CINEXO,
          datosFuncion.cine_id,
          datosFuncion.funcion_id
        )
      );
      setIsLoading(false);
    }
  }, [dispatch, isLoading, datosFuncion]);

  useEffect(() => {
    let flag = 0;
    datosCarrito.forEach((element) => {
      if (element.butaca !== "") flag += 1;
    });
    if (flag === datosCarrito.length) {
      setDisabledContinue(false);
      const pelicula = datosPelicula.datos.pelicula;
      if (pelicula) {
        if (cine == "1007") {
          dispatch(setNextPage(`/payment-methods${location.search}`));
        } else {
          if (pelicula.vende_candy === "1") {
            dispatch(setNextPage(`/candy${location.search}`));
          }
          if (pelicula.vende_candy === "0") {
            dispatch(setNextPage(`/fidelizacion${location.search}`));
          }
        }
      }
    } else {
      setDisabledContinue(true);
      setNextPage(`/butacas${location.search}`);
    }
  }, [datosCarrito, datosPelicula.datos.pelicula, location, dispatch]);

  useEffect(() => {
    if (windowSize.width < windowSize.height) setIsVertical(true);
    else setIsVertical(false);
  }, [windowSize]);

  const limpiarButacas = (event) => {
    if (datosCarrito && datosCarrito.length > 0) {
      for (let i = 0; i < datosCarrito.length; i++) {
        if (datosCarrito[i].butaca !== "") {
          dispatch(eliminarButaca(datosCarrito[i].butaca));
        }
      }
    }
  };

  return isLoading && !errorWs ? (
    ""
  ) : (
    <Box
      marginTop={1}
      sx={{
        maxWidth: "1024px",
        height: "auto",
        marginX: "auto",
        paddingX: { xs: "10px", md: "5px" },
      }}
    >
      <Grid container spacing="2rem">
        <Grid
          item
          xs={isVertical ? 12 : 8}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <PeliculaSimple />
          <Headline title={titleHeading} />
          {datosButaca.datosButaca[0] ? (
            <ButacasSeleccion butacas={datosButaca.datosButaca[0]} />
          ) : (
            <Box
              marginTop={1}
              sx={{
                maxWidth: "1024px",
                height: "auto",
                marginX: "auto",
                marginBottom: "4rem",
                padding: "0",
              }}
            >
              <Stack alignItems="center" margin="auto" marginTop={15}>
                <CircularProgress color="secondary" />
              </Stack>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={isVertical ? 12 : 4}
          height={isVertical ? "auto" : `${windowSize.height}px`}
        >
          <Carrito
            nextPage={nextPage}
            isVertical={isVertical}
            disabledCancel={false}
            disabledContinue={disabledContinue}
            step={"butacas"}
            to={`/tarifas${location.search}`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Butacas;

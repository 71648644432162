import axios from "axios";
import apihost from "../../../config/apiHost.json";

const DP = apihost.REACT_APP_URL_DALBE_PAYMENTS;
const DL = apihost.REACT_APP_URL_DALBE_LOGS;

const getDate = () => {
  // Obtener la fecha actual
  let fecha = new Date();
  // Incrementar la fecha en 10 minutos
  fecha.setMinutes(fecha.getMinutes() + 10);
  // Obtener la zona horaria
  let offset = fecha.getTimezoneOffset();
  let horas = Math.abs(Math.floor(offset / 60));
  let minutos = Math.abs(offset % 60);
  let signo = offset > 0 ? "-" : "+";
  if (signo === "-") {
    fecha.setHours(fecha.getHours() - horas);
  }
  if (signo === "+") {
    fecha.setHours(fecha.getHours() + horas);
  }
  // Obtener la fecha en el formato deseado
  let fechaFormateada = fecha
    .toISOString()
    .replace(
      "Z",
      `${signo}${horas.toString().padStart(2, "0")}:${minutos
        .toString()
        .padStart(2, "0")}`
    );
  return fechaFormateada;
};

export const ventasTemporales = async (data) => {
  try {
    let response = await axios({
      // !Desarrollo Checkout
      url: `${apihost.REACT_APP_URL_CHECKOUT_CINEXO}/ventasTemporales`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: data,
    });
    return response;
  } catch (error) {
    return false;
  }
};

export const newAmount = async (respuesta, hash) => {
  let dataToSend = {
    success: respuesta.success,
    data: {
      transactionAmount: respuesta.data.mercadoPago.transactionAmount,
      countryParameters: {
        name: respuesta.data.mercadoPago.countryParameters.name,
        areaCode: respuesta.data.mercadoPago.countryParameters.areaCode,
        currency: respuesta.data.mercadoPago.countryParameters.current,
        decimalPlaces: 2,
      },
      gatewayData: {
        gateway: "mercadopago",
        subtype: "ATM",
        credential: {
          publicKey: respuesta.data.mercadoPago.envPublicKey,
        },
      },
      amountOptions: {
        minAmountAllowed: 2,
        absorbedByCinema: false,
        feesAbsorbedByCinema: false,
      },
    },
  };
  let response = await axios({
    method: "GET",
    // !testing
    url: `${DP}/api/payments/amount/${respuesta.data.mercadoPago.complexCode}/mercadopago/ATM/${respuesta.data.mercadoPago.referenceId}/${respuesta.data.mercadoPago.transactionAmount}`,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        //!productivo
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
        //!testing
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
        // //!VM
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
        // !New token (HF)
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
        // !New Token
        `Bearer ${hash}`,
    },
    data: dataToSend,
  });
  if (response.data && response.data.success) {
    return response;
  } else {
    let dataToSend = {
      success: respuesta.success,
      data: {
        transactionAmount: respuesta.data.mercadoPago.transactionAmount,
        countryParameters: {
          name: respuesta.data.mercadoPago.countryParameters.name,
          areaCode: respuesta.data.mercadoPago.countryParameters.areaCode,
          currency: respuesta.data.mercadoPago.countryParameters.current,
          decimalPlaces: 2,
        },
        gatewayData: {
          gateway: "mercadopago",
          subtype: "-",
          credential: {
            publicKey: respuesta.data.mercadoPago.envPublicKey,
          },
        },
        amountOptions: {
          minAmountAllowed: 2,
          absorbedByCinema: false,
          feesAbsorbedByCinema: false,
        },
      },
    };
    let response = await axios({
      method: "GET",
      // !testing
      url: `${DP}/api/payments/amount/${respuesta.data.mercadoPago.complexCode}/mercadopago/-/${respuesta.data.mercadoPago.referenceId}/${respuesta.data.mercadoPago.transactionAmount}`,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          //!productivo
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
          //!testing
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
          // //!VM
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
          // !New token (HF)
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
          // !New Token
          `Bearer ${hash}`,
      },
      data: dataToSend,
    });
    if (response.data && response.data.success) {
      return response;
    }
  }
  return false;
};

export const paymentWithPoint = async (
  respuesta,
  data,
  device_id,
  codigo_pais,
  hash
) => {
  try {
    const referenceId = respuesta.data.mercadoPago.referenceId;
    const observation = respuesta.data.mercadoPago.observation;
    const transactionAmount = respuesta.data.mercadoPago.transactionAmount;
    let dataToSend = {};
    if (codigo_pais !== "AR") {
      dataToSend = {
        payment: {
          external_reference: referenceId,
          title: "Compra en Cine",
          description: observation,
          total_amount: transactionAmount,
          items: [
            {
              sku_number: "EC1",
              category: "BOLETERIA/CANDY",
              title: "Compra en Cine",
              description: "Compra en Cine",
              total_amount: transactionAmount,
            },
          ],
          device_id: device_id,
          payer: {
            payerEmail: data.payer_email,
            payerName: data.full_name,
          },
          print_on_terminal: true,
          referenceId: referenceId,
          paymentType: "point",
        },
        gatewayPayload: {
          id: "a204ad8b-1f47-469d-b234-d9abf6a44c31",
          bin: "432959",
        },
      };
    } else {
      dataToSend = {
        payment: {
          external_reference: referenceId,
          title: "Compra en Cine",
          description: observation,
          total_amount: transactionAmount,
          items: [
            {
              sku_number: "EC1",
              category: "BOLETERIA/CANDY",
              title: "Compra en Cine",
              description: "Compra en Cine",
              total_amount: transactionAmount,
            },
          ],
          device_id: device_id,
          ticket_number: "B7C88",
          payer: {
            payerEmail: data.payer_email,
            payerName: data.full_name,
          },
          print_on_terminal: true,
          referenceId: referenceId,
          paymentType: "point",
        },
        gatewayPayload: {
          id: "a204ad8b-1f47-469d-b234-d9abf6a44c31",
          bin: "432959",
        },
      };
    }
    let response = await axios({
      method: "POST",
      // !testing
      url: `${DP}/api/payments/payment`,
      // !produccion
      // url: "https://dalbe.cinexo.com.ar:3001/api/payments/payment",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          //!productivo
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
          //!testing
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
          // //!VM
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
          // !New token (HF)
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
          // !New Token
          `Bearer ${hash}`,
      },
      data: dataToSend,
    });

    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const paymentWithQR = async (respuesta, data, external_id, hash) => {
  try {
    const referenceId = respuesta.data.mercadoPago.referenceId;
    const observation = respuesta.data.mercadoPago.observation;
    const transactionAmount = respuesta.data.mercadoPago.transactionAmount;
    let dataToSend = {
      payment: {
        external_reference: referenceId,
        title: "Compra en Cine",
        description: observation,
        total_amount: transactionAmount,
        expiration_date: getDate(),
        items: [
          {
            sku_number: "KS955RUR",
            category: "BOLETERIA/CANDY",
            title: "Compra en Cine",
            description: "Compra en Cine",
            total_amount: transactionAmount,
            unit_measure: "unit",
            quantity: 1,
            unit_price: transactionAmount,
          },
        ],
        external_pos_id: external_id,
        payer: {
          payerEmail: data.payer_email,
          payerName: data.full_name,
        },
        referenceId: referenceId,
        paymentType: "mpqr",
      },
    };

    let response = await axios({
      method: "POST",
      // !testing
      url: `${DP}/api/payments/payment`,
      // !produccion
      // url: "https://dalbe.cinexo.com.ar:3001/api/payments/payment",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          //!productivo
          //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
          //!testing
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
          // //!VM
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
          // !New token (HF)
          // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
          // !New Token
          `Bearer ${hash}`,
      },
      data: dataToSend,
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getStatusPaymentPoint = async (id, complexCode, subtype, hash) => {
  let response = await axios({
    method: "GET",
    //!Dalbe
    url: `${DP}/api/payments/point/status/${id}/${complexCode}/mercadopago/${subtype}`,
    headers: {
      //!Dalbe
      Authorization:
        //!productivo
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
        //!testing
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
        // //!VM
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
        // !New token (HF)
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
        // !New Token
        `Bearer ${hash}`,
    },
  });
  return response;
};

export const getSatatusPaymentMP = async (id) => {
  const tokenMP = "";
  let response = await axios({
    method: "GET",
    // !MP
    url: `https://api.mercadopago.com/v1/payments/${id}`,
    // url: `https://testing.dalbepayment.nexoserver.com.ar/api/payments/paymentInfo/122300/mercadopago/-/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        //!MP
        `Bearer ${tokenMP}`,
      // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzAyMDYyMzIyLCJleHAiOjE3MTc2MTQzMjJ9.8pdfVU7Muv5GxLQXgTvxAlJYJdl6z5BrnB9tLtkvx1c",
    },
  });
  return response;
};

export const getStatusPayment = async (complexCode, id, subtype, hash) => {
  let response = await axios({
    method: "GET",
    //!Dalbe
    url: `${DP}/api/payments/paymentInfo/${complexCode}/mercadopago/${subtype}/${id}`,
    headers: {
      //!Dalbe
      Authorization:
        //!productivo
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
        //!testing
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
        // //!VM
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
        // !New token (HF)
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
        // !New Token
        `Bearer ${hash}`,
    },
  });
  return response;
};

export const sendRequestIPNDalbe = async (
  referenceId,
  dataToSend,
  complexCode,
  subtype,
  hash
) => {
  let response = await axios({
    method: "POST",
    //!Dalbe
    url: `${DP}/api/ipn/${complexCode}/mercadopago/${subtype}/point/${referenceId}`,
    headers: {
      //!Dalbe
      Authorization:
        //!productivo
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
        //!testing
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
        // //!VM
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
        // !New token (HF)
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
        // !New Token
        `Bearer ${hash}`,
    },
    data: dataToSend,
  });
  return response;
};

export const confirmarVentaCheckout = async (
  complexCode,
  referenceId,
  gateway
) => {
  try {
    let response = await axios({
      method: "POST",
      // !Desarrollo Checkout
      // url: `http://testing.nexoserver.com.ar:81/desarrollo/CheckoutCinexo/ventas?complexCode=${complexCode}&gateway=mercado_pago&referenceId=${referenceId}`,
      url: `${apihost.REACT_APP_URL_CHECKOUT_CINEXO}/ventas?complexCode=${complexCode}&gateway=${gateway}&referenceId=${referenceId}`,
    });
    if (
      (response.data && response.data.success) ||
      response.data.status === "procesado"
    ) {
      return response;
    } else {
      let response = await axios({
        method: "POST",
        // !Desarrollo Checkout
        // url: `http://testing.nexoserver.com.ar:81/desarrollo/CheckoutCinexo/ventas?complexCode=${complexCode}&gateway=mercado_pago&referenceId=${referenceId}`,
        url: `${apihost.REACT_APP_URL_CHECKOUT_CINEXO}/ventas?complexCode=${complexCode}&gateway=${gateway}&referenceId=${referenceId}`,
      });
      return response;
    }
  } catch (error) {
    return false;
  }
};

export const paymentWithCash = async (respuesta, data) => {
  console.log(respuesta, data);
  let response = await axios({
    method: "POST",
    url: `${apihost.REACT_APP_URL_CHECKOUT_CINEXO}/ventas?complexCode=${respuesta.data.cinema.complexCode}&gateway=${respuesta.data.cinema.paymentGateway}&referenceId=${respuesta.data.cinema.referenceId}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export const getStatusPaymentQR = async (referenceId, hash) => {
  let response = await axios({
    method: "GET",
    //!Dalbe
    url: `${DL}/api/trackingGatewayResponse/responseByReferenceId/payment/${referenceId}`,
    headers: {
      //!Dalbe
      Authorization:
        //!productivo
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY0NDE1NTY3LCJleHAiOjE2Nzk5Njc1Njd9.B4Y_cFdQh0pFYG1iPj4ePo6JAw9vGo9cdUJOxuShCNA",
        //!testing
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY2MTkwODcwLCJleHAiOjE2ODE3NDI4NzB9.B0DPomt4pUZ02wKNLPpbWHIy58O46cuUJY9_MRAJ1b4",
        // //!VM
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc3NjIwOTM2LCJleHAiOjE2OTMxNzI5MzZ9._wEussX7nmouQhBMkPBvtiA6uab2MXDwBQdkc8Yq_wk",
        // !New token (HF)
        // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjc4MTI1MTM5LCJleHAiOjE2OTM2NzcxMzl9.w7fYFVkcQ1h0I40MXFpARoCn_A1AP2ThCMoP7Xd53nc",
        // !New Token
        `Bearer ${hash}`,
    },
  });
  return response;
};

export const getTramaPreVenta = async (data) => {
  let response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_URL_CINEXO}impresiones/ticketPreventa`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });

  console.log(response.data);
  return response.data;
};

export const cancelPaymentIntention = async (
  deviceID,
  paymentID,
  complexCode,
  hash,
  subtype
) => {
  let response = await axios({
    url: `${DP}/api/payments/point/${deviceID}/${paymentID}/${complexCode}/mercadopago/${subtype}`,
    method: "DELETE",
    headers: {
      //!Dalbe
      Authorization: `Bearer ${hash}`,
    },
  });
  return response;
};

import {
  CARGAR_DATOS_EMPRESA,
  PAYMENT_CANCEL_INTENTION,
  PAYMENT_DALBE_ERROR,
  PAYMENT_ERROR,
  PAYMENT_ERROR_STEP3,
  PAYMENT_ERROR_VENTAS_TEMPORALES,
  PAYMENT_GENERAR_INTENCION_DE_PAGO,
  PAYMENT_INCREMENT_RETRY,
  PAYMENT_POINT_RETRY,
  PAYMENT_RETRY_QR,
  PAYMENT_SET_DATA_TO_SEND,
  PAYMENT_SET_METHOD,
  PAYMENT_SET_QR_DATA,
  PAYMENT_SET_RESPONSE_VT,
  RESET_PAYMENT_ID,
  RESET_QR_DATA,
  RESET_REDUX_STATE,
  SET_STATUS_SETP3,
  STATUS_PAYMENT,
} from "../actionTypes/actionTypes";

const initialState = {
  dataToSend: {},
  methodSelected: "",
  paymentID: "",
  referenceID: "",
  qrData: "",
  statusPaymentCash: null,
  deviceId: "",
  responseStep1: {},
  retries: 0,
  statusProcessQR: "",
  external_id: "",
  paymentError: false,
  statusStep1: "",
  statusStep3: "",
  codigo_pais: "",
  subtype: null,
  hash: null,
  only_qr: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_PAYMENT:
      return {
        ...state,
        statusPaymentCash: action.payload.statusPaymentCash,
      };
    case PAYMENT_SET_DATA_TO_SEND:
      return {
        ...state,
        dataToSend: action.payload.dataToSend,
      };
    case PAYMENT_SET_METHOD:
      return {
        ...state,
        methodSelected: action.payload.method,
      };
    case PAYMENT_GENERAR_INTENCION_DE_PAGO:
      return {
        ...state,
        paymentID: action.payload.paymentID,
        referenceID: action.payload.referenceID,
        responseStep1: action.payload.responseStep1,
        subtype: action.payload.subtype,
        hash: action.payload.hash,
      };
    case PAYMENT_POINT_RETRY:
      return {
        ...state,
        paymentID: action.payload.paymentID,
        referenceID: action.payload.referenceID,
        responseStep1: action.payload.responseStep1,
      };
    case RESET_PAYMENT_ID:
      return {
        ...state,
        paymentID: "",
      };
    case RESET_QR_DATA:
      return {
        ...state,
        qrData: "",
      };
    case PAYMENT_SET_QR_DATA:
      return {
        ...state,
        qrData: action.payload.qrData,
        referenceID: action.payload.referenceID,
        responseStep1: action.payload.responseStep1,
        subtype: action.payload.subtype,
        hash: action.payload.hash,
      };
    case PAYMENT_RETRY_QR:
      return {
        ...state,
        qrData: action.payload.qrData,
        referenceID: action.payload.referenceID,
      };
    case PAYMENT_INCREMENT_RETRY:
      return {
        ...state,
        retries: state.retries + 1,
      };
    case PAYMENT_CANCEL_INTENTION:
      return {
        ...state,
        paymentID: "",
      };
    case CARGAR_DATOS_EMPRESA:
      return {
        ...state,
        deviceId: action.payload.device_id,
        external_id: action.payload.external_id,
        codigo_pais: action.payload.codigo_pais,
        only_qr: action.payload.only_qr,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        paymentError: true,
      };
    case PAYMENT_ERROR_VENTAS_TEMPORALES:
      return {
        ...state,
        statusStep1: "failed",
      };
    case PAYMENT_DALBE_ERROR:
      return {
        ...state,
        statusStep3: "failed",
      };
    case RESET_REDUX_STATE:
      return initialState;
    case PAYMENT_SET_RESPONSE_VT:
      return {
        ...state,
        responseStep1: action.payload.data,
      };
    case SET_STATUS_SETP3:
      return {
        ...state,
        statusStep3: action.payload.status,
      };
    default:
      return state;
  }
};

export default paymentReducer;
